
<script setup>
    //abcdefgh
    import { ref, watch, reactive, computed, onMounted, onBeforeUnmount } from "vue";

    const props = defineProps(["modelValue", "show", "minHeight", "maxHeight", "fullscreen", "teleportTarget"]);
    const emit = defineEmits(["update:modelValue", "change", "show", "hide"]);

    const minHeight = computed(() => {
        if (props.fullscreen != null || props.minHeight === "max") {
            return "calc(100% - 4rem)";
        }
        return props.minHeight || "auto";
    });

    const maxHeight = computed(() => {
        if (props.fullscreen != null || props.maxHeight === "max") {
            return "calc(100% - 4rem)";
        }
        return props.maxHeight || "calc(100% - 4rem)";
    });

    const modal = ref(null);

    const state = {
        start_x: 0,
        start_y: 0,
        vel_x: 0,
        vel_y: 0,
        x: 0,
        y: 0,
        cancel: false,
        prevent: false,
        velocity: [],
    };

    watch(() => props.modelValue, () => {
        if (props.modelValue) {
            // show modal
            modal.value.style.transition = "all 300ms ease-in-out";
            modal.value.style.transform = "translate(0%, 0%)";
            emit("show");
            /* cool idea to mimic fullscreen bottom sheets from iOS, needs some fine-tuning and consideration */
            /*
            document.getElementById("app").style.inset = "0.5rem";
            document.getElementById("app").style.borderRadius = "0.5rem";
            document.getElementById("app").style.fontSize = "0.9em";
            */
        } else {
            // hide modal
            modal.value.style.transition = "all 200ms ease-in-out";
            modal.value.style.transform = "translate(0%, 100%)";
            emit("hide");
            /*
            document.getElementById("app").style.inset = "0";
            document.getElementById("app").style.borderRadius = "0";
            document.getElementById("app").style.fontSize = "1em";
            */
        }
    });

    function onTouchStart(e) {
        state.prev_x = e.touches[0].clientX;
        state.prev_y = e.touches[0].clientY;
        state.prev_t = e.timeStamp;
        state.vel_x = 0;
        state.vel_y = 0;
        state.cancel = false;
        state.prevent = false;
        state.firstFrame = true;

        if (!props.modelValue) {
            state.cancel = true;
        }
    }

    function onTouchMove(e) {
        if (!props.modelValue) {
            state.cancel = true;
        }
        
        // precalculate
        const target = e.target.closest(".scrollable") || e.target;

        const dif_x = e.touches[0].clientX - state.prev_x;
        const dif_y = e.touches[0].clientY - state.prev_y;

        // always cancel if prevent-swipe
        if (e.target.closest(".prevent-swipe")) {
            state.cancel = true;
        }

        // on first frame, check if we are allowed to swipe based on scroll
        if (state.firstFrame) {
            // vertical scrolling
            //if (Math.abs(dif_y) > Math.abs(dif_x)) {

            // if (e.target.closest(".prevent-swipe")) {
                // state.cancel = true;
            // }

            // check if vertical scrolling is available
            if (target.scrollHeight > target.clientHeight) {
                if (dif_y >= 0) {
                    // when scrolling up, cancel swiping if scroll is not at the top
                    if (target.scrollTop !== 0) {
                        state.cancel = true;
                    }
                } else {
                    // when scrolling down, cancel swiping if scroll is not at the bottom
                    if (target.scrollTop !== (target.scrollHeight - target.clientHeight)) {
                        state.cancel = true;
                    }
                }
            }

            // check if horizontal scrolling is available
            if (target.scrollWidth > target.clientWidth) {
                if (dif_x >= 0) {
                    if (target.scrollLeft !== 0) {
                        state.cancel = true;
                    }
                } else {
                    if (target.scrollLeft !== (target.scrollWidth - target.clientWidth)) {
                        state.cancel = true;
                    }
                }
            }

            //} else {
                /*
                if (dif_x >= 0) {
                    if (target.scrollLeft !== 0) {
                        state.cancel = true;
                    }
                } else {
                    if (target.scrollLeft !== (target.scrollWidth - target.clientWidth)) {
                        state.cancel = true;
                    }
                }
                */
            //}
        }

        // either cancel swiping to allow for scroll, or prevent scrolling to allow for swipe
        if (state.cancel) {
            return;
        } else {
            e.preventDefault();
        }

        // update state
        state.x += dif_x;
        state.y += dif_y;

        state.vel_y = dif_y/(e.timeStamp - state.prev_t);

        state.prev_x = e.touches[0].clientX;
        state.prev_y = e.touches[0].clientY;
        state.prev_t = e.timeStamp;

        // update visual
        let offset_y = state.y;
        if (offset_y < 0) {
            offset_y *= -1;
            offset_y **= 0.75;
            offset_y *= -1;
        }

        modal.value.style.transition = "all 0ms ease-in-out";
        modal.value.style.transform = `translate(0%, ${offset_y}px)`;

        state.firstFrame = false;
    }

    function onTouchEnd(e) {
        if (state.cancel) {
            return;
        }

        if (state.vel_y > 0.5 || (state.vel_y >= 0 && state.y > modal.value.clientHeight/2)) {
            emit("update:modelValue", false);
        } else {
            modal.value.style.transition = "all 400ms ease-in-out";
            modal.value.style.transform = "translate(0%, 0%)";
        }

        state.x = 0;
        state.y = 0;
    }

    onMounted(() => {
        if (modal.value) {
            modal.value.addEventListener("touchstart", onTouchStart);
            modal.value.addEventListener("touchmove", onTouchMove);
            modal.value.addEventListener("touchend", onTouchEnd);
        }
    });

    onBeforeUnmount(() => {
        if (modal.value) {
            modal.value.removeEventListener("touchstart", onTouchStart);
            modal.value.removeEventListener("touchmove", onTouchMove);
            modal.value.removeEventListener("touchend", onTouchEnd);
        }
    });
</script>

<template>
    <div>
        <teleport :to="teleportTarget || 'body'" >
            <div>
                <!-- backdrop -->
                <div
                    class="c-bottom-sheet-backdrop"
                    :class="{ 'show': modelValue }"
                    style="position: fixed; inset: 0; background-color: rgba(0%, 0%, 0%, 40%); z-index: 2100;"
                    @click="$emit('update:modelValue', false)"
                >
                </div>

                <!-- modal -->
                <div
                    ref="modal"
                    class="c-bottom-sheet-modal d-flex flex-column"
                    :class="{ 'show': modelValue, 'c-bottom-sheet-reset': !modelValue }"
                    style="position: fixed; left: 0; right: 0; bottom: 0; border-radius: 1rem 1rem 0 0; background-color: white; z-index: 2110; pointer-events: auto;"
                    :style="{ 'min-height': minHeight, 'max-height': maxHeight }"
                >
                    <slot name="header">
                        <div class="flex-shrink-0 d-flex" style="position: relative; height: 4rem;">
                            <!-- title -->
                            <div class="d-flex justify-content-center align-items-center" style="position: absolute; inset: 0; font-size: 1.25em; font-weight: 500;">
                                <slot name="title">{{ $t("Title") }}</slot>
                            </div>

                            <!-- action -->
                            <div class="d-flex align-items-center" style="position: absolute; top: 0; left: 0; bottom: 0;">
                                <slot name="action" />
                            </div>

                            <a
                                class="d-flex justify-content-center align-items-center"
                                style="position: absolute; top: 0; right: 0; bottom: 0; aspect-ratio: 1; text-decoration: none; color: rgb(60%, 60%, 60%);"
                                @click="emit('update:modelValue', false)"
                            >
                                <i class="bi bi-x" style="font-size: 1.5em;" />
                            </a>
                        </div>
                    </slot>
                    
                    <!-- subheader -->
                    <slot name="subheader">
                    </slot>

                    <!-- body -->
                    <div class="flex-grow-1 scrollable" style="margin-top: -0.75rem; position: relative; overflow-y: auto; overscroll-behavior: none;">
                        <slot name="body" />
                    </div>

                    <!-- drag handle -->
                    <div style="position: absolute; top: 6px; left: 50%; transform: translate(-50%, 0%); width: 4rem; height: 4px; background-color: rgb(80%, 80%, 80%); border-radius: 999px;">
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<style scoped>
    .c-bottom-sheet-backdrop {
        transition: all 400ms ease-in-out;

        pointer-events: none;
        opacity: 0%;

        &.show {
            pointer-events: auto;
            opacity: 100%;
        }
    }

    .c-bottom-sheet-modal {
        transition: all 400ms ease-in-out;

        box-shadow: none;
        transform: translate(0%, 100%);

        &.show {
            box-shadow: 0 0 16px 0 rgba(0%, 0%, 0%, 15%);
            transform: translate(0%, 0%);
        }
    }

    .c-bottom-sheet-reset {
        transition: all 400ms ease-in-out;
        transform: translate(0, 100%);
    }
</style>
